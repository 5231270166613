import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";
import Form from "/src/components/forms/Form";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";
import Col from "../../components/styled/grid/Col";
import {Body, Title} from "../../components/styled/typography/Typography";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const RequestTrial = ({data}) => {

    const {price} = data

    return (
        <Layout lang={price.lang} seo={price.SEO}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid customBackground="middle">
                <Col>
                    <Title color="white" marginBottom="normal">
                        {price.title}
                    </Title>
                    <Body color="body-text">
                        {parse(price.description)}
                    </Body>
                </Col>
                <Col>
                    <Form form={price} lang={price.lang} action="callback"/>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSinglePrice($locale: String) {
         price: strapiRequestPrices(lang: { eq: $locale }) {
          lang
          title
          description
          Form {
            country
            email
            first_name
            help_dropdown
            id
            last_name
            message
            phone
            preffered_dropdown
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default RequestTrial